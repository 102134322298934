import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import log_img from './assets/logo.png';
import './App.css';

function App() {
  const timeout = 100;
  useEffect(() => {
    const timer = setTimeout(() => {
      // window.location.href = 'https://foefestival.wsj.com/event/the-future-of-everything-festival/';
      window.location.href = 'https://webxr.run/AWL5157WxbXn';
    }, timeout);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className='App'>
      {/* <div className='d-flex flex-column align-items-start min-vh-100'>
        <div className='w-100 text-center'>
          <img src={log_img} alt='' className='logo-img mt-2' />
        </div>
        <div className='flex-fill w-100 position-relative'>
          <div
            style={{ height: '100px' }}
            className='w-100 position-absolute top-50 translate-middle-y'
          >
            <h3 className='mt-2'>COMING SOON!</h3>
            <h4 className='mt-3'>October 22, 2022</h4>
          </div>
        </div>
        <div className='text-center w-100 footer py-2'>
          <a
            href='https://www.goodwin.edu/'
            className='text-decoration-none text-white fw-normal fs-6'
          >
            Goodwin University
          </a>
        </div>
      </div> */}
    </div>
  );
}

export default App;
